<template>
    <v-container class="pa-0" fluid>
        <page-title
                :heading="$t('erp.lang_Zusatzstoffe')"
                :icon="icon"
                :subheading="$t('erp.lang_EditAdditive')"
                show-previous-button

        ></page-title>
        <div class="app-main__inner">
            <additives-edit-component/>
        </div>
    </v-container>
</template>

<script>
    import PageTitle from "../../../../Layout/Components/PageTitle";
    import AdditivesEditComponent from "../../../../components/erp/settings/additive/AdditivesEditComponent";
    export default {
        name: "AdditivesEdit",
        components: {AdditivesEditComponent, PageTitle},
        data(){
            return{
                icon:"pe-7s-tools icon-gradient bg-tempting-azure",
            }
        }
    }
</script>

<style scoped>

</style>